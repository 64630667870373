import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import styled from '@emotion/styled'
import tw from 'tailwind.macro'
import { graphql, useStaticQuery } from 'gatsby'
import ImageZoom from 'react-medium-image-zoom'
import { owl } from '../styles'
import dayjs from 'dayjs'

const NewsItemsContainer = styled.div`
  ${tw`mb-3`}
  ${owl(tw`mt-16`)};
`

const NewsItemContainer = styled.div`
  ${tw`lg:flex rounded border`};
`

const NewsItemTitle = styled.h2`
  ${tw`m-0 mb-2 text-2xl text-black`};
`

const NewsItemDate = styled.div`
  ${tw`text-sm text-grey-darkest`};
`

const NewsItemContent = styled.div`
  ${tw`w-full lg:w-2/3`};
`

const NewsItemImage = styled.div`
  ${tw`mr-6 flex-no-shrink w-full md:w-1/2 lg:w-1/3`};
  img {
    ${tw`w-full`}
  }
`

const NewsItemTextWrapper = styled.div`
  ${tw`text-black`};

  > p {
    ${tw`m-0`}
  }
`

const News = ({ limit = Infinity }) => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      articles: allSanityArticle {
        edges {
          node {
            _id
            title
            datePublished
            image {
              imageText
              image {
                asset {
                  sm: fluid(maxWidth: 600, maxHeight: 400) {
                    ...GatsbySanityImageFluid
                  }

                  lg: fluid(maxWidth: 1200, maxHeight: 800) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            _rawText
          }
        }
      }
    }
  `)

  const articles = data.articles.edges
    .map(e => e.node)
    .slice(0, limit)
    .sort((a, b) => new Date(b.datePublished) - new Date(a.datePublished))

  return (
    <NewsItemsContainer className="articles-root">
      {articles.map(article => (
        <NewsItemContainer key={article._id}>
          <NewsItemImage>
            <ImageZoom
              image={{
                alt: article.image.imageText,
                src: article.image.image.asset.sm.src
              }}
              zoomImage={{
                alt: article.image.imageText,
                src: article.image.image.asset.lg.src
              }}
              shouldReplaceImage={false}
            />
          </NewsItemImage>

          <NewsItemContent>
            <NewsItemDate>
              {dayjs(article.datePublished).format('DD.MM.YYYY | HH:MM')}
            </NewsItemDate>
            <NewsItemTitle>{article.title}</NewsItemTitle>
            <NewsItemTextWrapper>
              <BlockContent blocks={article._rawText} />
            </NewsItemTextWrapper>
          </NewsItemContent>
        </NewsItemContainer>
      ))}
    </NewsItemsContainer>
  )
}

export default News
