import Layout from '../components/layout'
import Heading from '../components/heading'
import SEO from '../components/seo'
import { ContainerLg } from '../components/container'
import News from '../components/news'
import React from 'react'

export default () => (
  <Layout>
    <ContainerLg>
      <SEO title="Aktuelles" />
      <Heading level={1}>Aktuelles</Heading>
      <News />
    </ContainerLg>
  </Layout>
)
